import React from 'react'
import Prices from '../templates/price-page'

export default class PricesIndexPage extends React.Component {
  render() {
    const {location} = this.props;
    return (
        <Prices location={location}/>
    )
  }
}
